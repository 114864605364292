import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import axios from 'axios';
import { URL } from '../service/url';
import moment from 'moment'
import mqtt from 'mqtt'
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';



export default function Summery_Update(props) {


  const Swal = require('sweetalert2')

  
  const admindetail=JSON.parse(localStorage.getItem('admindata'))

  const [fromdate,setFromDate]=useState('')
  const [todate,setToDate]=useState('')
  const [hide, setHide] = useState(false);
  const [unshowModal, setUNShowModal] = useState(false);
  // const [pergain,setPerGain]=useState(0)
  const [watchlistdata,setWatchListData]=useState([])
  const [selectedwatchlist,setSelectedWatchList]=useState({})
  const [watchshowModal,setWatchShowModal]=useState(false)
  const [showchart,setchartshow]=useState(false)
  const [newapisummarydata,setNewApiSummaryData]=useState([])
  


  

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const now = new Date();
  //     const hours = now.getHours();
  //     const minutes = now.getMinutes();
  //     const second = now.getSeconds();

  //     if (hours === 12 && minutes === 9 && second === 1) {
  //       console.log('hiii');
  //     } else {
  //       console.log('by');
  //     }
  //   }, 1000);

   
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const columnDefs = useMemo(() => [

    { field: 'DATE',width: 80, suppressSizeToFit: true ,cellStyle: { textAlign: 'center' }},
    // { field: 'NML',width: 80, suppressSizeToFit: true},
    // { field: 'MTF',width: 80, suppressSizeToFit: true},
    { field: 'REALISED',width: 100, suppressSizeToFit: true,
      cellClassRules: {
        'text-danger': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value < 0;
        },
        'text-success': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value >= 0;
        }
      }
    },
    { field: 'UNREALISED', width: 110, suppressSizeToFit: true,
      cellClassRules: {
        'text-danger': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value < 0;
        },
        'text-success': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value >= 0;
        }
      }
    },
    { field: 'BROKERAGE',width: 80, suppressSizeToFit: true},
    { field: 'GAIN',width: 80, suppressSizeToFit: true},
    // { field: 'HOLDING',width: 80, suppressSizeToFit: true  },
    // { field: 'MARKET_VALUE',width: 80, suppressSizeToFit: true  },
   


    // .......................................................................
    // { field: 'DUES',width: 100, suppressSizeToFit: true,  },
    // { field: 'MARGIN CASH UTILISED',width: 80, suppressSizeToFit: true  },
    // { field: 'MTF UTILISED',width: 80, suppressSizeToFit: true  },
    // { field: 'MARGIN CASH AVAILABLE', width: 110, suppressSizeToFit: true },
    // { field: 'MTF AVAILABLE', width: 110, suppressSizeToFit: true },
    // ...........................................................................
], []);

const defaultColDef = useMemo(() => ({
  // resizable: true,
  // sortable: true,
  // filter:true,
  // floatingFilter:true,
  // cellRenderer:false,
  // flex:1,
  // minWidth: 100,
  // editable:true,
  // suppressSizeToFit: true,
  
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:false,
  flex:1,
  minWidth: 100,
  suppressSizeToFit: true
}), []);

const [rowData, setRowData] = useState([]);



// fakedataat....................................................

// const fakedata=[
//     {
//         "DATE": "14/08/2024",
//         "UNREALISED": "54,758,223",
//         "REALISED": "53,173,935",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "1,500,000",
//         "NML": "2,500,000",
//         "BROKERAGE": "60,000",
//         "GAIN": "12.5"
//     },
// 	{
//         "DATE": "13/08/2024",
//         "UNREALISED": "52,718,163",
//         "REALISED": "50,123,915",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "3,812,047",
//         "NML": "4,958,672",
//         "BROKERAGE": "41,500",
//         "GAIN": "15.3"
//     },
// 	{
//         "DATE": "12/08/2024",
//         "UNREALISED": "50,438,263",
//         "REALISED": "43,173,985",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "6,254,718",
//         "NML": "7,290,831",
//         "BROKERAGE": "55,780",
//         "GAIN": "8.1"
//     },
// 	{
//         "DATE": "11/08/2024",
//         "UNREALISED": "53,308,233",
//         "REALISED": "52,083,965",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "5,891,743",
//         "NML": "8,461,237",
//         "BROKERAGE": "30,110",
//         "GAIN": "19.2"
//     },
// 	{
//         "DATE": "10/08/2024",
//         "UNREALISED": "54,388,213",
//         "REALISED": "53,113,935",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "4,110,285",
//         "NML": "7,290,821",
//         "BROKERAGE": "22,110",
//         "GAIN": "13.8"
//     },
// 	{
//         "DATE": "09/08/2024",
//         "UNREALISED": "41,718,223",
//         "REALISED": "40,133,915",
//         "BUY_VALUE": "0",
//         "SELL_VALUE": "0",
//         "TOTAL_NET_VALUE": "0",
//         "MTF": "1,952,046",
//         "NML": "3,265,718",
//         "BROKERAGE": "50,600",
//         "GAIN": "17.5"
//     }
// ]


// fakedataat....................................................



useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  // setBrokerName(props.body[0].brokername)
  // setBrokerType(props.body[0].brokertype)


},[props])


const [brokerid,setBrokerid]=useState()
// const [brokername,setBrokerName]=useState()
// const [brokertype,setBrokerType]=useState()
const [invested ,setInvested]=useState('0')






const holdingdata=async()=>{





  var formdata=new FormData()
  formdata.append('bro_id',brokerid)
  formdata.append('admin_id',admindetail.user_id)
  var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=retrieve`,formdata)

  const investedHolding = result.data.data.map(item => item.INVESTED_HOLDING);
  const investedHoldingNumber = investedHolding.map(value => parseFloat(value?.replace(/,/g, '')));
  const totalinvestedHolding = investedHoldingNumber.reduce((acc, val) => acc + val, 0);
  const formattedTotalInvested = totalinvestedHolding.toLocaleString(); 
  setInvested(formattedTotalInvested);


  
  // console.log('realised',rowData[0].REALISED)
  // console.log('realised',typeof(rowData[0].REALISED))
  // console.log('invested',formattedTotalInvested)
  // console.log('invested',typeof(formattedTotalInvested))

  // let Gain=((parseFloat(rowData[0].REALISED)/parseFloat(formattedTotalInvested))*100).toFixed(3)
  // setPerGain(Gain)

  
  // var negativeSum = 0;
  // var positiveSum = 0;
  
  // result.data.data.map((val) => {
  //     if (val.PER_GAIN < 0) {
  //         negativeSum += Number(val.PER_GAIN.toFixed(3));
  //     } else if (val.PER_GAIN > 0) {
  //         positiveSum += Number(val.PER_GAIN.toFixed(3));
  //     }
  // });
  

  // const formattedNegativeSum = negativeSum.toFixed(3);
  // const formattedPositiveSum = positiveSum.toFixed(3);
  // setPerGain((formattedPositiveSum - formattedNegativeSum).toFixed(3));

  // negativeSum

}

useEffect(()=>{

// holdingdata()

},[brokerid])



// get mqtt data ........................................................

  

const MQTT_HOST = 'wss://test.mosquitto.org:8081';


const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)
const [MQTT_TOPICADMIN,setMqtt_TopicAdmin]=useState(`testtopic/amitjha/6666`)

const [mqttClient, setMqttClient] = useState(null);
const [mqttdata,setMqttData]=useState([])
const [mqttdataadmin,setMqttDataAdmin]=useState([])






const [mqttCurrentHolding,setMqttCurrentHolding]=useState()
const [mqttInvestedHolding,setMqttInvestedHolding]=useState()
const [mqttGain,setMqttGain]=useState(0)
const [mqttRealised,setMqttRealised]=useState()
const [mqttUnrealised,setMqttUnrealised]=useState()

const [mqttCurrentHoldingh,setMqttCurrentHoldingh]=useState()
const [mqttInvestedHoldingh,setMqttInvestedHoldingh]=useState()
const [mqttGainh,setMqttGainh]=useState(0)
const [mqttRealisedh,setMqttRealisedh]=useState()
const [mqttUnrealisedh,setMqttUnrealisedh]=useState()






//  console.log(mqttRealised,'mqttRealised')



useEffect(()=>{


  if(brokerid===undefined || brokerid===null)
  {
    setMqtt_Topic(`testtopic/amitjha/2222`)
    setMqtt_TopicAdmin(`testtopic/amitjha/6666`)

  }
  else
  {
    setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
  }

},[brokerid])




useEffect(() => {
const client = mqtt.connect(MQTT_HOST);

client.on('connect', () => {

  setMqttClient(client);

  client.subscribe(MQTT_TOPIC);
  client.subscribe(MQTT_TOPICADMIN)
 

});

return () => {

  client.end(); 

};

}, [MQTT_TOPIC]);






useEffect(() => {


const handleMessage = (topic, message) => {

  const parsedMessage = JSON.parse(message.toString());

  if(topic===MQTT_TOPIC)
    {
      setMqttData(parsedMessage)
    }
  else if (topic === MQTT_TOPICADMIN)  
    {
      setMqttDataAdmin(parsedMessage)
    }

  // setMqttData(JSON.parse(message.toString()));
  

};

if (mqttClient) {
  mqttClient.on('message', handleMessage);
}

return () => {
  if (mqttClient) {
    mqttClient.off('message', handleMessage); 
  }
};

}, [mqttClient]); 


useEffect(() => {

  
  const investedHolding = mqttdata.reduce((sum, item) => {
    const value = parseFloat(item.INVESTED_HOLDING.replace(/,/g, '') || 0);
    return sum + (value || 0);
  }, 0);

  const currentHolding = mqttdata.reduce((sum, item) => {
    const value = parseFloat(item.CURRENT_HOLDING || 0);
    return sum + (value || 0);
  }, 0);

  const realised = mqttdata.reduce((sum, item) => {
    const value = typeof item.REALISED === 'string'
      ? parseFloat(item.REALISED.replace(/,/g, ''))
      : item.REALISED || 0;
    return sum + (value || 0);
  }, 0);

  const unrealised = mqttdata.reduce((sum, item) => {
    const value = typeof item.UNREALISED === 'string'
      ? parseFloat(item.UNREALISED.replace(/,/g, ''))
      : item.UNREALISED || 0;
    return sum + (value || 0);
  }, 0);

  const formattedInvestedHolding = investedHolding.toLocaleString();
  const formattedCurrentHolding = currentHolding.toLocaleString();
  const formattedRealised = realised.toLocaleString();
  const formattedUnrealised = unrealised.toLocaleString();

  setMqttInvestedHolding(formattedInvestedHolding);
  setMqttCurrentHolding(formattedCurrentHolding);
  setMqttRealised(formattedRealised);
  setMqttUnrealised(formattedUnrealised);


  if (mqttdataadmin && mqttdataadmin.length > 0) {
    const adminData = mqttdataadmin[0];
    setMqttInvestedHoldingh(adminData.INVESTED_HOLDING || '0');
    setMqttCurrentHoldingh(adminData.CURRENT_HOLDING || '0');
    setMqttRealisedh(adminData.TOTAL_REALIZED || '0');
    setMqttUnrealisedh(adminData.TOTAL_UNREALISED || '0');
    setMqttGainh(adminData.TOTAL_PER_GAIN || '0.00');
  }



  // Calculate gain
  if (mqttdata.length > 0) {
    let currentGain = (parseFloat(realised) / parseFloat(investedHolding)) * 100;
    if (!isNaN(currentGain)) {

      setMqttGain(currentGain);

    } else {

      setMqttGain('0.00');

    }
  } else {

    setMqttGain('0.00');

  }



}, [mqttdata, mqttdataadmin,brokerid]);



const filterStockDetails = (watchlist, stockDetails) => {
  const watchlistStockNames = watchlist.map(item => item.stock_name);
  const filteredStockDetails = stockDetails.filter(stock => 
  watchlistStockNames.includes(stock.STOCKS)
  );

  return filteredStockDetails;
};





const imageRef = useRef(null);
const [isRotating, setIsRotating] = useState(false);

const rotateStyle = {
  transition: 'transform 1s linear',
  transform: isRotating ? 'rotate(360deg)' : 'rotate(0deg)',
};

const getwatchlist = async () => {


  if (imageRef.current) {
    setIsRotating(true);

   
    setTimeout(() => {
      setIsRotating(false);
    }, 1000); 
  }

  var formdata = new FormData();
  formdata.append('admin_id', admindetail.user_id);
  const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=Getwatchlist`,formdata);
  const filteredStockData = filterStockDetails(result.data.data,mqttdata);
  setWatchListData(filteredStockData)
 

};

useEffect(()=>{

  getwatchlist()

},[mqttdata])









// useEffect(()=>{



//   const investedHolding = mqttdata.reduce((sum, item) => {
//     const value = parseFloat(item.INVESTED_HOLDING.replace(/,/g, '') || 0);
//     return sum + (value || 0);
//   }, 0);

//   const currentHolding = mqttdata.reduce((sum, item) => {
//     const value = parseFloat(item.CURRENT_HOLDING || 0);
//     return sum + (value || 0);
//   }, 0);

//   const realised = mqttdata.reduce((sum, item) => {
//     const value = typeof item.REALISED=== 'string' 
//       ? parseFloat(item.REALISED.replace(/,/g, '')) 
//       : item.REALISED|| 0;
//     return sum + (value || 0);
//   }, 0);

//   const unrealised = mqttdata.reduce((sum, item) => {
//     const value = typeof item.UNREALISED === 'string' 
//       ? parseFloat(item.UNREALISED.replace(/,/g, '')) 
//       : item.UNREALISED || 0;
//     return sum + (value || 0);
//   }, 0);


//   console.log(typeof(investedHolding),'investedHolding')
//   console.log(typeof(currentHolding),'currentHolding')
//   console.log(typeof(realised),'realised')
//   console.log(typeof(unrealised),'unrealised')

//   setMqttInvestedHolding(investedHolding);
//   setMqttCurrentHolding(currentHolding);
//   setMqttRealised(realised);
//   setMqttUnrealised(unrealised);

 

//   if(mqttdata.length>0)
//     {
//       let currentgain=(JSON.parse(realised)/JSON.parse(investedHolding))*100

//       setMqttGain(currentgain)
//     }
//   else
//   {
//     setMqttGain(0)
//   }


// },[mqttdata,brokerid])





// get mqtt data ........................................................


// useEffect(() => {

  


//   if (rowData.length > 0 &&  invested !== '0'  ) {
     
//       let Gain = ((parseFloat(rowData[0].REALISED) / parseFloat(invested)) * 100).toFixed(2);
    
//       setPerGain(Gain);
//   }
//   else
//   {
   
//       setPerGain(0);
//   }

  

// }, [rowData, invested]);



// useEffect(() => {
//   if (rowData.length > 0 && rowData[0] && invested !== '0') {
//     const REALISED = rowData[0].REALISED;
//     const investedValue = invested;
//     console.log(REALISED,investedValue,'donoketype')

//     if (REALISED && investedValue && investedValue !== 0 ) {


    


//       let Gain =  REALISED / investedValue * 100 

//       console.log(Gain,'Gain...........')
      

//       setPerGain(Gain);
//     } else {
//       console.error('Invalid input values or division by zero');
//       setPerGain(0);
//     }
//   } else {
//     console.warn('rowData is empty or rowData[0] is undefined or invested is "0"');
//     setPerGain(0);
//   }
// }, [rowData, invested]);




// .............................................CURRENT HOLDING... 

const getholdingdata=async()=>{

    var formdata=new FormData()
    let formdatee = moment(fromdate).format(' YYYY-MM-DD ');
    let todatee = moment(todate).format(' YYYY-MM-DD ');
  


    if(fromdate=='' && todate=='')
    {
      formdata.append('bro_id',brokerid)
      formdata.append('admin_id',admindetail.user_id)
      formdata.append('firsttime',0)
      var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getsummary`,formdata)
      // console.log('withoutdatedata',result)

      
      setRowData(result.data.data.reverse())

    }
    else
    {
      formdata.append('bro_id',brokerid)
      formdata.append('admin_id',admindetail.user_id)
      formdata.append('firsttime',1)
      formdata.append('from_date',formdatee)
      formdata.append('to_date',todatee)  
      var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getsummary`,formdata)
      
      setRowData(result.data.data.reverse())

    }
    
  
    
   }

useEffect(()=>{
  
  getholdingdata()

},[fromdate,todate,brokerid])



// .......................................................new summary api............



const getnewsummarydata=async()=>{

 
  
  var formdata=new FormData()
  if(brokerid===undefined)
    {
      formdata.append('broker_id',null)
      formdata.append('admin_id',admindetail.user_id)
      let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=summary_detail`,formdata)
    
      setNewApiSummaryData(result.data.data)
    }
    else
    {
      formdata.append('broker_id',brokerid)
      formdata.append('admin_id',admindetail.user_id)
      let result = await axios.post(`${URL}stockInfo&bpuser=10891&action=summary_detail`,formdata)
    
      setNewApiSummaryData(result.data.data)
    }
 

  
 }

useEffect(()=>{

  getnewsummarydata()

},[brokerid])






// .......................................................new summary api............





// useEffect(() => {

  

//   if (rowData.length > 0 && rowData[0] && invested !== '0') {

//     const REALISED = parseFloat(mqttRealised.replace(/,/g, ''));
//     const investedValue = parseFloat(mqttUnrealised.replace(/,/g, ''));
    
   

//     if (REALISED && investedValue && investedValue !==0 || investedValue ==0 ) {
      
//       const Gain =  (REALISED / investedValue * 100).toFixed(2)

//       setPerGain(Gain);

//     } else {
//       console.error('Invalid input values or division by zero');
//       setPerGain(0);
//     }
//   } else {
//     console.warn('rowData is empty or rowData[0] is undefined or invested is "0"');
//     setPerGain(0);
//   }

    



// }, [mqttdata]);




// .........................................END CURRENT HOLDING...



// console.log('summaryid',brokerid)
// console.log('summaryname',brokername)
// console.log('summarytype',brokertype)

// .....................................

const getdate=()=>{

  let  dates = document.getElementById('reservation').value
  let  fromdate=dates.split('-')
  setFromDate(fromdate[0])
  setToDate(fromdate[1])

  // var body={fromdat:fromdate ,todate:todate}
  // var bi ='date'

  // dispatch({type:'brokeridset',payload:[bi,body]})
  

}
// var dates  = useSelector((state)=>state.date) 
// console.log('dateeeeeeeeeeeeee',dates)



// console.log("rowData",rowData)











const handleRowClicked = (event) => {


  setWatchShowModal(true)
  setSelectedWatchList(event.data)


};


const ModalWatchlist = ({show,selectedwatchlist}) => {

   
 


 const Deletefromwatchlist=async()=>{

  var formdata = new FormData();
  formdata.append('admin_id', admindetail.user_id);
  formdata.append('stock_name', selectedwatchlist.STOCKS);

  try {
    const result = await axios.post(`${URL}stockInfo&bpuser=10891&action=deletewatch`, formdata);
    if(result.data.res_code===1)
      {
        setWatchShowModal(false)
        Swal.fire({
          position: 'top-center',
          icon: 'success',
          title: 'Stock delete from watchlist',
          showConfirmButton: false,
          timer: 2000
        })
        getwatchlist()

      }


  }
  catch
  {
    console.log('stock is not delete from watchlist..')
  }


 }



  return ( 
   
    <div className={`modal fade ${show ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-md" role="document">

        <div className="modal-content">

          <div className="modal-header" >

           <h5 className="modal-title">{selectedwatchlist.STOCKS}</h5>

          
           
            <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={()=>setWatchShowModal(false)}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body"  >
          

            <div style={{display:'flex',justifyContent:'center',alignItems:'center',padding:'15px',width:"100%",height:'10vh',fontSize:'20px',fontWeight:'50px'}} >
              You Want To Delete This From Watchlist
            </div>
            <div style={{display:'flex',justifyContent:'space-evenly',alignItems:'center',padding:'20px'}}>
              <button  onClick={()=>Deletefromwatchlist()} style={{padding:'8px 20px 8px 20px',color:'#fff',background:"red",fontSize:'20px'}}>Yes</button>
              <button onClick={()=>setWatchShowModal(false)} style={{padding:'8px 20px 8px 20px',color:'#fff',background:"green",fontSize:'20px'}} >No</button>
            </div>

              
          </div>

         
        </div>


      </div>


    </div>


  );

};









// model wrap.............................................................................


const Modal = ({ hide }) => {


 


  const columnDefs = useMemo(() => [
    { 
      field: 'STOCKS', 
      resizable: true,   
      suppressSizeToFit: true,
      minWidth: 250,
      cellStyle: { textAlign: 'left' }
    },
    { 
      field: 'REALISED',
      cellRenderer: params => {
        const value = parseFloat(params.value.replace(/,/g, ''));
        const isNegative = value < 0;
        return (
          <div className="text-end">
            {params.value}
            {isNegative ? (
              <img style={{width:"15px",position:'absolute',left:'50%'}} src='/assets/down.png' />
            ) : (
              <img style={{width:"15px",position:'absolute',left:'50%'}} src='/assets/up.png' />
            )}
          </div>
        );
      },
      cellClassRules: {
        'text-danger': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value < 0;
        },
        'text-success': params => {
          const value = parseFloat(params.value.replace(/,/g, '')); 
          return value => 0;
        },
        
      },
      resizable: true,
      suppressSizeToFit: true,
     cellStyle: { textAlign: 'left' },
      minWidth: 250,
    }
  ], []);

  // DefaultColDef sets props common to all Columns
  
  const defaultColDef = useMemo(() => ({

    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    maxWidth:400,
    suppressSizeToFit: true

  }), []);
  






  return ( 
   
    <div className={`modal fade ${hide ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: hide ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div className="modal-content">

          <div className="modal-header">

          <h5 className="modal-title">REALISED</h5>

          
           
            <button   type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() =>setHide(false)}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body">
          

              <div className="ag-theme-alpine" style={{ height: '400px', width: '765px' }}>
              <AgGridReact
                rowData={mqttdata}
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef} 
              />
              </div>

    

          </div>

         
        </div>


      </div>


    </div>


  );

};



const UNModal = ({ unshowModal }) => {


  const columnDefs = useMemo(() => [
    { 
      field: 'STOCKS', 
      resizable: true,   
      suppressSizeToFit: true,
      minWidth: 250,
      cellStyle: { textAlign: 'left' }
    },
    { 
      field: 'UNREALISED',
      cellRenderer: params => {
        const value = parseFloat(params.value.toString().replace(/,/g, ''));
        const isNegative = value < 0;
        return (
          <div className="text-end">
            {params.value}
            {isNegative ? (
              <img style={{width:"15px",position:'absolute',left:'50%'}} src='/assets/down.png' />
            ) : (
              <img style={{width:"15px",position:'absolute',left:'50%'}} src='/assets/up.png' />
            )}
          </div>
        );
      },
      cellClassRules: {
        'text-danger': params => {
          const value = parseFloat(params.value.toString().replace(/,/g, '')); 
          return value < 0;
        },
        'text-success': params => {
          const value = parseFloat(params.value.toString().replace(/,/g, '')); 
          return value >= 0;
        }
      },
     
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: { textAlign: 'Left' },
      minWidth: 250
    }
  ], []);

  // DefaultColDef sets props common to all Columns
  
  const defaultColDef = useMemo(() => ({

    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    maxWidth:400,
    suppressSizeToFit: true

  }), []);





  return ( 
   
    <div className={`modal fade ${unshowModal ? 'show d-block' : ''}`} id="exampleModalToggle" aria-hidden="true" tabIndex="-1" role="dialog" style={{ display: unshowModal ? 'block' : 'none' }}>

      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">

        <div className="modal-content">

          <div className="modal-header">

          <h5 className="modal-title">UNREALISED</h5>

          
           
            <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => setUNShowModal(false)}>
             
             
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>

            </button>

          </div>

          <div className="modal-body">
          

              <div className="ag-theme-alpine" style={{ height: '400px', width: '765px' }}>
              <AgGridReact
                rowData={mqttdata}
                columnDefs={columnDefs} 
                defaultColDef={defaultColDef} 
              />
              </div>

    

          </div>

         
        </div>


      </div>


    </div>


  );

};

// model wrap..................................................................................


    const columnDefsWL = useMemo(() => [
      { field: 'STOCKS',width: 100, suppressSizeToFit: true, headerTooltip: 'Stocks' , cellStyle: { textAlign: 'left' } },
      { field: 'CURRENT_MARKET_PRICE',width: 70, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
      { field: '%CHNG',width: 90, suppressSizeToFit: true , cellStyle: { textAlign: 'left' },
      cellClassRules: {
        'text-danger': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value < 0;
        },
        'text-success': params => {
          
          const value = params.value ? parseFloat(params.value.toString().replace(/,/g, '')) : 0;
          return value >= 0;
        }
      } }
      // { field: 'CHANGES', width: 110, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }},

    ], []);

    const defaultColDefWL = useMemo(() => ({
    resizable: true,
    sortable: true,
    filter:true,
    floatingFilter:true,
    cellRenderer:false,
    flex:1,
    minWidth: 100,
    editable:true,
    suppressSizeToFit: true,
    sortable: true
    // flex:1
    }), []);



    
// .....................................................................ApexChart............



const ApexChart = () => {

  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    const dateArray = [];
    const mtfData = [];
    const nmlData = [];
    const gainData = [];
    const brokerageData = [];
    const realisedData = [];
    const unrealisedData = [];

    // Populate the arrays with data from your object array
    newapisummarydata.forEach(item => {
      dateArray.push(item.DATE);
      mtfData.push(parseFloat(item.MTF.replace(/,/g, '')));
      nmlData.push(parseFloat(item.NML.replace(/,/g, '')));
      gainData.push(parseFloat(item.GAIN));
      brokerageData.push(parseFloat(item.BROKERAGE.replace(/,/g, '')));
      realisedData.push(parseFloat(item.REALISED.replace(/,/g, '')));
      unrealisedData.push(parseFloat(item.UNREALISED.replace(/,/g, '')));
    });

    // Set the series data for ApexChart
    setSeries([
      { name: 'MTF', data: mtfData },
      { name: 'NML', data: nmlData },
      { name: 'GAIN', data: gainData },
      { name: 'BROKERAGE', data: brokerageData },
      { name: 'REALISED', data: realisedData },
      { name: 'UNREALISED', data: unrealisedData },
    ]);
    

    // Set the options, ensuring that the dates array is fully populated
    setOptions({
      chart: {
        height: 350,
        type: 'area'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: [dateArray],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        },
      },
    });

  }, [newapisummarydata]);





  return (
    <div>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="area" height={520} />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};



// .....................................................................ApexChart............



  return (
    <>
  {/* <div className="card sticky-top"> */}

  {/* ..............................................................dashboard */}
  <div className='card' style={{padding:'20px 20px 0px 20px'}}>

<h1 style={{marginLeft:'37%',paddingBottom:'10px',color:'#212529'}}>{('Dashboard').toUpperCase()}</h1>

<hr></hr>

<div className="row" style={{disply:'flex',justifyContent:'center'}}>

<div class="col-lg-4 col-6">


{/* className={rowData[0].HOLDING.includes("-") ? "small-box bg-danger" : "small-box bg-info"} */}

  <div  className="small-box" style={{background:'#dddd'}} >
    <div className="inner">
      <h3 style={{ color: mqttCurrentHolding === undefined || mqttCurrentHolding === null || mqttCurrentHolding.includes('-') ? 'red' : 'green' }}  >{ brokerid===undefined || brokerid===null ?  mqttCurrentHoldingh : mqttCurrentHolding}</h3>
      <p style={{color:'#0009'}}>CURRENT HOLDING</p>
    </div>
    <div className="icon">
     <i className="ion ion-stats-bars"></i>
    </div>
    {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
  </div>

</div>


<div className="col-lg-4 col-6">

{/* className={typeof invested === 'string' && invested.includes("-") ? "small-box bg-danger" : "small-box bg-info"} */}

  <div className="small-box" style={{background:'#dddd'}} >
  <div className="inner">
  <h3 style={{ color: mqttInvestedHolding === undefined || mqttInvestedHolding === null || mqttInvestedHolding.includes('-') ? 'red' : 'green' }}  >{ brokerid===undefined || brokerid===null ? mqttInvestedHoldingh : mqttInvestedHolding}</h3>
  <p style={{color:'#0009'}}>INVESTED HOLDING</p>
  </div>
  <div className="icon">
  <i className="ion ion-stats-bars"></i>
  </div>
  {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
  </div>

</div>






<div className="col-lg-3 col-6">

{/* className={rowData[0].TOTAL_NET_VALUE.includes("-") ? "small-box bg-danger" : "small-box bg-info"} */}

  <div className="small-box" style={{background:'#dddd'}} >
  <div className="inner">
  <h3  style={{ color: brokerid === undefined || brokerid === null 
        ? (mqttGainh === undefined || mqttGainh === null || mqttGainh < 0) 
            ? 'red' 
            : 'green' 
        : (mqttGain === undefined || mqttGain === null || mqttGain < 0) 
            ? 'red' 
            : 'green'
        }}  >
        {brokerid===undefined || brokerid===null ? mqttGainh : mqttInvestedHolding!=0 && mqttRealised!=0 ? mqttGain.toFixed(2):"0.00"} %
        </h3>

  {/* mqttGain.includes('-') ? 'red' : */}

  <p style={{color:'#0009'}}  >% GAIN</p>
  </div>
  <div className="icon">
  <i className="ion ion-stats-bars"></i>
  </div>
  {/* <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> */}
  </div>
  </div>



</div> 
               
<div className="row"  style={{disply:'flex',justifyContent:'center'}} >

  <div className="col-lg-4 col-6" >

    {/* className={rowData[0].REALISED.includes("-") ? "small-box bg-danger" : "small-box bg-info"} */}

    <div className="small-box" style={{background:'#dddd'}}>
    <div className="inner">
    <h3 style={{ color: brokerid === undefined || brokerid === null 
        ? (mqttRealisedh === undefined || mqttRealisedh === null || (typeof mqttRealisedh === 'string' && mqttRealisedh.includes('-'))) 
            ? 'red' 
            : 'green' 
        : (mqttRealised === undefined || mqttRealised === null || (typeof mqttRealised === 'string' && mqttRealised.includes('-'))) 
            ? 'red' 
            : 'green'
          }}>
             { brokerid===undefined || brokerid===null ? mqttRealisedh : mqttRealised}
             </h3>
    <p style={{color:'#0009'}} >REALISED</p>
    </div>
    <div className="icon">
      <i className="ion ion-stats-bars"></i>
    </div>
    <a onClick={()=>setHide(true)}  href='#' class="small-box-footer" style={{color:'black'}}><i class="fas fa-list"></i></a>
    </div>

  </div>


  <div className="col-lg-4 col-6"  >

    {/* className={rowData[0].UNREALISED.includes("-") ? "small-box bg-danger" : "small-box bg-info"} */}

    <div className="small-box" style={{background:'#dddd'}} >
      
      <div className="icon">
        <i className="ion ion-stats-bars"></i>
      </div>
      <div className="inner">


        <h3  style={{ color:  brokerid === undefined || brokerid === null 
        ? mqttUnrealisedh === undefined || mqttUnrealisedh === null || (typeof mqttUnrealisedh === 'string' && mqttUnrealisedh.includes('-')) 
            ? 'red' 
            : 'green' 
        : mqttUnrealised === undefined || mqttUnrealised === null || (typeof mqttUnrealised === 'string' && mqttUnrealised.includes('-')) 
            ? 'red' 
            : 'green'
        }} >

        {/* {mqttCurrentHolding !== '0' ? mqttUnrealised : '0'} */}
        
        {/* {rowData[0].UNREALISED} */}
          { brokerid===undefined || brokerid===null ? mqttUnrealisedh : mqttUnrealised}
        
        </h3>
        <p style={{color:'#0009'}}>UNREALISED</p>
      </div>
    
      <a onClick={()=>setUNShowModal(true)}  href='#' class="small-box-footer" style={{color:'black'}}><i class="fas fa-list"></i></a>
    </div>

  </div>

</div> 


     <Modal hide={hide}  />  
     <UNModal  unshowModal={unshowModal}   />  
     <ModalWatchlist  show={watchshowModal} selectedwatchlist={selectedwatchlist} />

</div>


  {/* ..............................................................dashboard */}


  <div className='row' >

 
   <div className="col-sm-4" >
 

     <div className="card" >
    <div className="card-header" >

        <div style={{display:"flex",justifyContent:'space-between'}}>

          <h3 className="card-title" >WATCHLIST</h3>
          <img  ref={imageRef}  onClick={()=>getwatchlist()}  id="clickImage"    style={{ width: '30px',...rotateStyle }}  src='../assets/refresh.png'/>

        </div>
   
      
    </div>
    <div className="card-body">  
    <div className="ag-theme-alpine" style={{width: '100%', height: 557}}>
    
        <AgGridReact className="ag-theme-alpine"

          rowData={watchlistdata}
          columnDefs={columnDefsWL}
          defaultColDef={defaultColDefWL}
          enableRangeSelection="true"
          enableCharts={true}
          animateRows="true"
          rowSelection="multiple"
          suppressRowClickSelection="true"
          pagination={false}
          onRowClicked={handleRowClicked}
       

        />


    </div>
       

    
    </div>

    
  </div>

  </div>

  <div  className="col-sm-8" >

 

            <div className="card" >
              <div className="card-header">
                <div className='row'>
                  <div className='col-md-3' style={{display:'flex'}}>

                    <h3 className="card-title">SUMMARY DETAILS</h3>
                 
                  </div>
                  <div className='col-md-6'>
                    
                    <div className='row'>
                     
                    
                      <div className='col-md-10'>
                
                        <div className="input-group" style={{width:'350px'}}>
                  
                          <div className="input-group-prepend">
                            <strong className='mr-2'  >Select Date</strong> 
                            <span className="input-group-text">
                            <i className="far fa-calendar-alt"></i>
                            </span>
                          </div>
                          <input type="text" className="form-control float-right" id="reservation" />
                        </div>

                      </div>

                      <div className='col-md-2'>

                        <div  onClick={()=>getdate()}   className='btn btn-secondary'> Search</div>

                      </div>
                      
                    
                    </div>
                    
                  </div>

                  <div className='col-md-3' style={{display:'flex',justifyContent:'right'}}>
                   <button onClick={()=>setchartshow(!showchart)} style={{margin:'0px 5px 5px 0px',background:'#007bff',color:'#ffff',padding:'5px'}}>CHART</button>
                  </div>
                 

                  {/* <div className='col-md-2'> */}
                    
                  {/* <div className="card-tools float-right"> */}
                    {/* <Link to="/Report" state={{brokerid}}><i className="fas fa-file-pdf"></i> View Report</Link> */}
{/*                    
                    &nbsp;
                  <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
                    
                    <i className="fas fa-minus"></i>

                  </button> */}
                  
                   {/* </div> */}


                  {/* </div> */}
                </div>
              </div>
                  <div className="card-body"  >


                     {showchart ? <>

                      <ApexChart/>

                     </>:<>

                      <div className="ag-theme-alpine" style={{width: '100%', height: 550}}>

                        <AgGridReact className="ag-theme-alpine" 
                        rowData={newapisummarydata}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableRangeSelection={true}
                        enableCharts={true}
                        suppressRowClickSelection="true"
                        rowSelection="multiple"
                        // animateRows='true'
                        // pagination={true}

                        />

                      </div>

                     </>}



                   
                  </div>
                  
                </div>

              </div>

      </div>



{rowData.length > 0 &&
<div>

  {/* <div className='card' style={{padding:'20px 20px 0px 20px',display:'flex',justifyContent:'center'}}>

   <h1 style={{alignSelf:'center'}}>{('Dashboard').toUpperCase()}</h1>

   <hr></hr>
  
   <h4 style={{alignSelf:'center',padding:"15px",background:'#0069d2',color:'#fff',borderRadius:'10px',marginBottom:'20px'}}>INDEX'S</h4>
  
    
    <div className="row" style={{disply:'flex',justifyContent:'center'}}>

      <div class="col-lg-4 col-6" >

        <div  className="small-box" style={{background:'#dddd'}} >
          
          <div className="inner">
            <h3 style={{ color: mqttCurrentHolding === undefined || mqttCurrentHolding === null || mqttCurrentHolding.includes('-') ? 'red' : 'green' }}  >{'soon....'}</h3>
            <p style={{color:'#0009'}}>NIFTY</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        
        </div>

      </div>
      <div class="col-lg-4 col-6" >

        <div  className="small-box" style={{background:'#dddd'}} >
          
          <div className="inner">
            <h3 style={{ color: mqttCurrentHolding === undefined || mqttCurrentHolding === null || mqttCurrentHolding.includes('-') ? 'red' : 'green' }}  >{'soon....'}</h3>
            <p style={{color:'#0009'}}>SENSEX</p>
          </div>
          <div className="icon">
            <i className="ion ion-stats-bars"></i>
          </div>
          <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
        
        </div>

      </div>

    </div>

  </div> */}




</div>
}
</>
  )
}
