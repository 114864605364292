import React, { useState, useRef, useEffect, useMemo, useCallback} from 'react';
//import { createRoot } from 'react-dom/client';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import 'ag-grid-enterprise';
import axios from 'axios';
import { URL } from './service/url';
import mqtt from 'mqtt'
import { toInteger, toNumber } from 'lodash';

export default function Consolidated(props) {


  const admindetail=JSON.parse(localStorage.getItem('admindata'))
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowTransData, setselectedRowTransData] = useState([]);
  const [currentholdingdata,setCurrentHoldingData]=useState([])
  const [currentholdingdatabackup,setCurrentHoldingDataBackUp]=useState([])
  const [selectstocklivedata,setSelectedStockLiveData]=useState({})
  const [selectedclintdata,setSelectedClintData]=useState({})
  const [unrealizedd,setUnrealizedd]=useState()
  


  const columnDefs = useMemo(() => [
    { field: 'STOCKS',width: 100, suppressSizeToFit: true, headerTooltip: 'Stocks' , cellStyle: { textAlign: 'left' } },
    { field: 'QTY',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    { field: 'CMP',width: 80, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }  },
    // { field: 'CHANGES', width: 110, suppressSizeToFit: true , cellStyle: { textAlign: 'left' }},
 
], []);

const defaultColDef = useMemo(() => ({
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:false,
  flex:1,
  minWidth: 100,
  editable:true,
  suppressSizeToFit: true,
  sortable: true,
 // flex:1
}), []);

const [rowData, setRowData] = useState();




// .....................................

useEffect(()=>{

  setBrokerid(props.body[0].brokerid)
  setBrokerName(props.body[0].brokername)
  setBrokerType(props.body[0].brokertype)


},[props])


const [brokerid,setBrokerid]=useState()
const [brokername,setBrokerName]=useState()
const [brokertype,setBrokerType]=useState()
const [selectedbrokername,setSelectedBrokerName]=useState('')

  // mqtt...code .................................
 
  

  const MQTT_HOST = 'wss://test.mosquitto.org:8081';
  const [MQTT_TOPIC,setMqtt_Topic]=useState(`testtopic/amitjha/2222`)
  const [mqttClient, setMqttClient] = useState(null);
  const [mqttdata,setMqttData]=useState([])



  
    useEffect(()=>{
  
       const zeronodata=mqttdata.filter((val)=>val.QTY !==0)
     
       setCurrentHoldingData(zeronodata)
       setCurrentHoldingDataBackUp(mqttdata)
  
  
    },[mqttdata])
  
  
  
    useEffect(()=>{
    
    
      if(brokerid===undefined || brokerid===null)
        {
          setMqtt_Topic(`testtopic/amitjha/2222`)
        }
      else
      {
         setMqtt_Topic(`testtopic/amitjha/${brokerid}`)
      }
  
    },[brokerid])
  
  
    
  
  useEffect(() => {
    const client = mqtt.connect(MQTT_HOST);
  
    client.on('connect', () => {
  
      setMqttClient(client);
  
      client.subscribe(MQTT_TOPIC);
  
    });
  
    return () => {
  
      client.end(); 
  
    };
  
  }, [MQTT_TOPIC]);
  
  
  
  
  
  
  useEffect(() => {
    
  
    const handleMessage = (topic, message) => {
  
      setMqttData(JSON.parse(message.toString()));
      
   
    };
  
    if (mqttClient) {
      mqttClient.on('message', handleMessage);
    }
  
    return () => {
      if (mqttClient) {
        mqttClient.off('message', handleMessage); 
      }
    };
  
  }, [mqttClient]); 
  
  



  // mqtt...code .................................





// .............................................CURRENT HOLDING... 

const getholdingdata=async()=>{
  

  var formdata=new FormData()
  

  
  formdata.append('admin_id',admindetail.user_id)
  formdata.append('bro_id',undefined)

  var result = await axios.post(`${URL}stockInfo&bpuser=10891&action=getallaccountDetails`,formdata)
 

  let data=result.data.data.filter((item)=>item.QTY!=0)
 

  setRowData(data)
  
 }

useEffect(()=>{

getholdingdata()

},[brokerid])



const handleRowClicked = (event) => {
  
  setSelectedRowData(event.data);

  const detaildata=currentholdingdata.filter((val)=>val['NSE Symbol']==event.data.STOCKS)
  setSelectedStockLiveData(detaildata[0])
  setShowModal(true);

};



const handleRowallClicked =async(event) =>{
 
  
  
  setSelectedClintData(event.data)

  setSelectedBrokerName(event.data.ACCOUNT_NAME)
  
  const formdata = new FormData();
  formdata.append('stock_name', event.data.STOCKS);
  formdata.append('admin_id', admindetail.user_id); 
  formdata.append('broker_id', event.data.broker_id); 
  const response = await axios.post(`${URL}stockInfo&bpuser=10891&action=singleScriptStock`, formdata);
  setselectedRowTransData(response.data.data)


}


const columnDefTran = useMemo(() => [
      
  { field: 'DATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'center' }
},
  { field: 'BUY_QUANTITY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},
  { field: 'BUY_RATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'BUY_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_QUANTITY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_RATE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'SELL_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'CUMULATIVE_VALUE',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:180,
  cellStyle: { textAlign: 'right' }
},
  { field: 'CUMULATIVE_QTY',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:180,
  cellStyle: { textAlign: 'right' }
},
  { field: 'REALISED',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:120,
  cellStyle: { textAlign: 'right' }
},
  { field: 'UNREALISED',
  resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:150,
  cellStyle: { textAlign: 'right' }
},

], []);


const defaultColDeTran = useMemo(() => ({
  editable:true,
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:true,
  flex:1,
  maxWidth: 300,
  suppressSizeToFit: true,

}), []);







const Modal = ({ show, rowData }) => {

    


  const [stockInfo, setStockInfo] = useState([]);
  const [loading, setLoading] = useState(true);


   


  const columnD = useMemo(() => [
    { field: 'SNO',
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:80,
      cellStyle: { textAlign: 'center' }
    },

    { field: 'ACCOUNT_NAME', 
      resizable: true ,   
      suppressSizeToFit: true ,
      minWidth:200,
      cellStyle: { textAlign: 'center' }
   
    } ,
    { field: 'STOCKS',width:100,
    resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'center' }
 
  } ,
    { field: 'QTY',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
    { field: 'AVERAGE_PRICE',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
  { field: 'CMP',width:100,resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:200,
  cellStyle: { textAlign: 'right' }

  },
    // { field: 'CMP',width:100,},
    { field: 'INVESTED_HOLDING',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
    { field: 'TOTAL_REALISE_PROFIT',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  } ,
  { field: 'INVESTED',width:100,resizable: true ,   
  suppressSizeToFit: true ,
  minWidth:200,
  cellStyle: { textAlign: 'right' }

},
    { field: 'CURRENT_HOLDING',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }
 
  },
  { 
    field: 'PER_GAIN',width:100,resizable: true ,   
    suppressSizeToFit: true ,
    minWidth:200,
    cellStyle: { textAlign: 'right' }

  }  

], []);


const defaultColD = useMemo(() => ({
  editable:true,
  resizable: true,
  sortable: true,
  filter:true,
  floatingFilter:true,
  cellRenderer:true,
  flex:1,
  maxWidth: 300,
  suppressSizeToFit: true,
  

}), []);





useEffect(()=>{

  const investedValue = selectedclintdata?.INVESTED
    ? Number(selectedclintdata.INVESTED.replace(/,/g, ""))
    : 0;

  const currentHoldingValue = selectedclintdata?.CURRENT_HOLDING
    ? Number(selectedclintdata.CURRENT_HOLDING.replace(/,/g, ""))
    : 0;

  const difference = investedValue - currentHoldingValue;
  setUnrealizedd(difference)

},[selectedclintdata])





  useEffect(() => {
    const fetchStockInfo = async () => {
      try {
        const formdata = new FormData();
        formdata.append('stock_name', rowData.STOCKS);
        formdata.append('admin_id', admindetail.user_id); 
        formdata.append('broker_id', null); 


        var response = await axios.post(`${URL}stockInfo&bpuser=10891&action=multipleScriptStock`,formdata)

        setStockInfo(response.data.data)
     
        setLoading(false);
      } catch (error) {
        console.error('Error fetching stock information:', error);
        setLoading(false);
      }
    };

    if (show && rowData) {
      fetchStockInfo();
    }
  }, [show, rowData]);

  if (!show) {
    return null;
  }


  const Doempty=()=>{

    setselectedRowTransData([])
    setSelectedBrokerName('')

  }


 

  return (
    <div className={`modal fade ${show ? 'show d-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{selectedRowData.STOCKS}</h5>
           
            <h6 style={{display:'flex'}} className="modal-title">CMP :&nbsp;<div style={{color:'#'}}>{selectstocklivedata?.CURRENT_MARKET_PRICE || 'No Value'}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">Day High :&nbsp;<div style={{color:'green'}}>{selectstocklivedata?.High || 'No Value' }</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">Day Low :&nbsp;<div style={{color:'red'}}>{selectstocklivedata?.Low || 'No Value'}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">52 Week High :&nbsp;<div style={{color:'green'}}>{selectstocklivedata?.["52Wk High"] || 'No Value'}</div></h6>
            <h6 style={{display:'flex'}} className="modal-title">52 Week Low :&nbsp;<div style={{color:'red'}}>{selectstocklivedata?.["52Wk Low"] || 'No Value'}</div></h6>

       
              <button  type="button" style={{background:'#ffff',border:'none',padding:'10px'}} aria-label="Close" onClick={() => {setShowModal(false)
              Doempty()}}>
              <img  style={{width:'20px'}}  src='../assets/cancel.png'/>
            </button>
          </div>
          <div className="modal-body" style={{display:'flex',flexDirection:'column',height:selectedbrokername?'700px':'350px'}}>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {Array.isArray(stockInfo) && stockInfo.length > 0 && (
                  <div className="ag-theme-alpine" style={{ height: '300px', width: '100%' }}>
                    <AgGridReact
                      rowData={stockInfo}
                      columnDefs={columnD}
                      defaultColDef={defaultColD}
                      onRowClicked={handleRowallClicked}
                    />
                  </div>
                )}
                {Array.isArray(selectedRowTransData) && selectedRowTransData.length > 0 && (
                  <div className="ag-theme-alpine " style={{ height:'300px', width: '100%'}}>
                   <div style={{display:'flex',alignItems:'center',justifyContent:'left',margin:'10px'}}>
                   <h5 className="modal-title" >{selectedbrokername}</h5>
                   </div>
                  
                    <AgGridReact
                      rowData={selectedRowTransData}
                      columnDefs={columnDefTran}
                      defaultColDef={defaultColDeTran}
                    />
                  </div>
                )}
                {(!Array.isArray(stockInfo) || stockInfo.length === 0) && !loading && (
                  <p>No data available</p>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
              <div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'10,10,10,10'}}>
              <h6  style={{display:'flex'}} className="modal-title">Invested Holding :&nbsp;<div >{selectedclintdata?.INVESTED_HOLDING}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Current Holding :&nbsp;<div >{selectedclintdata?.CURRENT_HOLDING}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Avg. Value :&nbsp;<div >{selectedclintdata?.AVERAGE_PRICE}</div></h6>
              <h6 style={{display:'flex'}} className="modal-title">Number of Shares :&nbsp;<div >{selectedclintdata?.QTY}</div></h6>
              </div>
              <div style={{display:'flex',justifyContent:'space-between',width:'100%',padding:'10,10,10,10',marginTop:"20px"}}>
               <h6 style={{display:'flex'}} className="modal-title">Unrealized :&nbsp;<div >{Number(unrealizedd).toLocaleString()}</div></h6>
               <h6 style={{ display: 'flex' }} className="modal-title">
              P&L :&nbsp;
              <div
                
              >
                {selectedclintdata?.TOTAL_REALISE_PROFIT}
              </div>
            </h6>
             </div>
          </div>
        </div>
      </div>
    </div>
  );
};


// .........................................END CURRENT HOLDING...


  return (
    <div className="card" >
    <div className="card-header">
      {/* <div style={{fontSize:'15px',fontWeight:"500"}}>CONSOLIDATED STOCK'S</div> */}
      <h3 className="card-title">CONSOLIDATED STOCK'S</h3>
      <div className="card-tools">
{/* 
       <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
          <i className="fas fa-minus"></i>
        </button>
          */}
      </div>
    </div>
    <div className="card-body">  
    <div className="ag-theme-alpine" style={{width: '100%', height: 590}}>
    
        <AgGridReact className="ag-theme-alpine"

          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          enableRangeSelection="true"
          enableCharts={true}
          animateRows="true"
          rowSelection="multiple"
          suppressRowClickSelection="true"
          pagination={false}
          onRowClicked={handleRowClicked}

        />


    </div>
       

    
    </div>

    <Modal show={showModal} rowData={selectedRowData} />
    
  </div>
   
           

   
  )
}
